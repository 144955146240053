<script>
import Icons from '../../mixins/Icons'

export default {
  mixins: [Icons],
}
</script>

<template>
  <!-- eslint-disable max-len no-mixed-spaces-and-tabs -->
  <svg
    :width="w"
    :height="h"
    role="presentation"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 62.4 49"
    style="enable-background:new 0 0 62.4 49;"
    xml:space="preserve"
  >
    <path
      :fill="color"
      d="M31.2,40.1c-2.5,0-4.5,2-4.5,4.5c0,2.5,2,4.5,4.5,4.5c2.5,0,4.5-2,4.5-4.5C35.6,42.1,33.6,40.1,31.2,40.1z M31.2,46.8
c-1.2,0-2.2-1-2.2-2.2c0-1.2,1-2.2,2.2-2.2c1.2,0,2.2,1,2.2,2.2C33.4,45.8,32.4,46.8,31.2,46.8z M31.2,26.7c-4.6,0-8.9,1.9-12.1,5.3
c-1.7,1.8-1.6,4.6,0.2,6.3c0.8,0.8,1.9,1.2,3.1,1.2c1.2,0,2.4-0.5,3.2-1.4c1.5-1.6,3.5-2.5,5.7-2.5c2.1,0,4.1,0.9,5.7,2.5
c0.8,0.9,2,1.4,3.2,1.4c1.1,0,2.2-0.4,3.1-1.2c0.9-0.8,1.4-1.9,1.4-3.1c0-1.2-0.4-2.3-1.2-3.2C40.1,28.6,35.8,26.7,31.2,26.7z
 M41.6,36.7c-0.9,0.8-2.3,0.8-3.1-0.1c-2-2.1-4.5-3.2-7.3-3.2c-2.7,0-5.3,1.1-7.3,3.2c-0.8,0.9-2.3,0.9-3.2,0.1
c-0.9-0.8-0.9-2.2-0.1-3.1c2.8-3,6.5-4.6,10.5-4.6c4,0,7.7,1.6,10.5,4.6c0.4,0.4,0.6,1,0.6,1.6C42.3,35.8,42.1,36.3,41.6,36.7z
 M31.2,13.4c-8,0-15.4,3.3-21.1,9.2c-0.8,0.9-1.3,2-1.2,3.2c0,1.2,0.5,2.3,1.4,3.1c0.8,0.8,1.9,1.2,3.1,1.2c1.2,0,2.4-0.5,3.2-1.4
c3.9-4.1,9.1-6.4,14.6-6.4c5.5,0,10.7,2.3,14.6,6.4c0.8,0.9,2,1.4,3.2,1.4c1.1,0,2.2-0.4,3.1-1.2c0.9-0.8,1.4-1.9,1.4-3.1
c0-1.2-0.4-2.3-1.2-3.2C46.6,16.6,39.2,13.4,31.2,13.4z M50.5,27.3c-0.9,0.8-2.3,0.8-3.1-0.1C43,22.6,37.3,20,31.2,20
c-6.1,0-11.9,2.5-16.2,7.1c-0.8,0.9-2.3,0.9-3.2,0.1c-0.4-0.4-0.7-0.9-0.7-1.5c0-0.6,0.2-1.2,0.6-1.6c5.2-5.5,12.1-8.5,19.4-8.5
c7.3,0,14.3,3,19.4,8.5c0.4,0.4,0.6,1,0.6,1.6C51.2,26.3,51,26.9,50.5,27.3z M61.2,13.1C53.2,4.7,42.5,0,31.2,0
c-11.3,0-22,4.7-30,13.1C0.4,14,0,15.1,0,16.3c0,1.2,0.5,2.3,1.4,3.1c0.8,0.8,1.9,1.2,3.1,1.2c1.2,0,2.4-0.5,3.2-1.4
C14,12.6,22.3,8.9,31.2,8.9c8.8,0,17.2,3.7,23.5,10.4c0.8,0.9,2,1.4,3.2,1.4c1.1,0,2.2-0.4,3.1-1.2C62.8,17.8,62.8,14.9,61.2,13.1z
 M59.4,17.8c-0.9,0.8-2.3,0.8-3.1-0.1c-6.7-7.1-15.6-11-25.1-11c-9.5,0-18.4,3.9-25.1,11c-0.8,0.9-2.3,0.9-3.1,0.1
c-0.4-0.4-0.7-1-0.7-1.6c0-0.6,0.2-1.2,0.6-1.6c7.6-8,17.6-12.4,28.4-12.4c10.7,0,20.8,4.4,28.3,12.4C60.4,15.6,60.3,17,59.4,17.8z"
    />
  </svg>
</template>
